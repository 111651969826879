import {
  RefObject, useEffect, useRef, useState,
} from 'react';
import StyledDiv, { AnimationDirection } from './styles';

type Props = {
  children: React.ReactNode;
  className?: string;
  direction: AnimationDirection;
  disable?: boolean;
};

function FadeInSection(props: Props): JSX.Element {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef() as RefObject<HTMLDivElement>;
  useEffect(() => {
    let isMounted = true;
    let firstPassDone = false;
    const observer = new IntersectionObserver((entries:IntersectionObserverEntry[]) => {
      if (isMounted) {
        entries.forEach((entry) => {
          // Disable animation on the way back.
          if (props.direction === AnimationDirection.Left && entry.boundingClientRect.left < 0) {
            return;
          }
          if (props.direction === AnimationDirection.Up && entry.boundingClientRect.top < 0) {
            return;
          }
          if (firstPassDone) {
            return;
          }
          setVisible(entry.isIntersecting);
          if (entry.isIntersecting) {
            firstPassDone = true;
          }
        });
      }
    });
    if (domRef.current != null) {
      observer.observe(domRef.current);
    }
    return () => { isMounted = false; };
  }, []);
  return (
    <>
      {
        props.disable
          ? <div className={`${props.className || ''}`}>
            {props.children}
          </div>
          : <StyledDiv
            className={`${props.className || ''} ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
            isVisible={isVisible}
            direction={props.direction}
          >
            {props.children}
          </StyledDiv>

      }
    </>
  );
}

export default FadeInSection;
