export type EventData = {
  name: string;
};

function subscribe(eventName:string, listener: EventListener): void {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName:string, listener: EventListener): void {
  document.removeEventListener(eventName, listener);
}

function publish(eventName:string, data: EventData): void {
  const event = new CustomEvent<EventData>(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };
