import styled from 'styled-components';
import { palette } from 'styled/constants';
import MediaBreakpoints from '../../styled/mediaBreakpoints';
import { ReactComponent as ArrowUpIcon } from '../../resources/up-arrow.svg';

export const StyledIcon = styled(ArrowUpIcon)`
  fill: currentColor;
  height: 40px;
  @media ${MediaBreakpoints.largeUp} {
    height: 80px;
  }
`;

export const StyledText = styled.span`
  font-size: 1rem;
  width: 100px;
  display: flex;
  align-items: center;
  justify-items: end;
  border: 1px solid;
  height: 100%;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
`;

export const StyledWrapepr = styled.div`
  background-color: ${palette.violeta};
  border-radius: 8px;
  height: 100% ;
  position: absolute;
  top: 0;
  z-index: -1;
`;

export const StyledButton = styled.div`
   bottom: 80px;
   color: ${(props) => props.theme.primary.textColor};
   cursor: pointer;
   height: 80px;
   font-size: 3rem;
   transition: opacity 0.5s ease;
   position: fixed;
   width: auto;
   z-index: 1;
   @media ${MediaBreakpoints.largeUp} {
     bottom: calc(50% - 40px);;
     left: 30px;
     width: 100px;
   }
   
`;
