import { useState, useEffect } from 'react';
import {
  StyledButton, StyledIcon,
} from './styles';

const BackToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = (scrolledPosition:number) => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 450 && scrolledPosition > 10) {
      setVisible(true);
    } else if (scrolled <= 450 || scrolledPosition < 10) {
      setVisible(false);
    }
  };
  const onScroll = () => {
    // This will calculate how many pixels the page is vertically
    const winScroll = document.documentElement.scrollTop;
    // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    // This will calculate the final total of the percentage of how much the user has scrolled.
    const scrolledPosition = (winScroll / height) * 100;
    // This will set the scrollTop to the scrolled value. This will make the button appear when the user has scrolled down a little bit.
    toggleVisible(scrolledPosition);
  };

  const scrollToTop = () => {
    setVisible(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <StyledButton style={{ opacity: visible ? 1 : 0, visibility: visible ? 'visible' : 'hidden' }} onClick={scrollToTop}>
      <StyledIcon />
    </StyledButton>
  );
};

export default BackToTop;
