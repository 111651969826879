import { LinkProps } from 'react-router-dom';
import { ContactButton, PrimaryButton, SecondaryButton } from './styles';

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  contact: 'contact',
};

type Props = {
  label: string;
  variant?: 'primary' | 'secondary' | 'contact';
  size?: 'sm' | 'md' | 'lg';
  link?: string;
  verticalAlignment?: boolean;
};

interface ButtonAttributes extends LinkProps {
  size?: 'sm' | 'md' | 'lg';
  $verticalAlignment: boolean;
}

function Button(props: Props): JSX.Element {
  const {
    label,
    variant = VARIANTS.primary,
    size,
    link,
    verticalAlignment,
  } = props;
  const attributes: ButtonAttributes = {
    size,
    role: 'button',
    $verticalAlignment: verticalAlignment || false,
    to: link || '#',
  };
  return (
    <>
      {variant === 'primary' && (
        <PrimaryButton {...attributes}>{label}</PrimaryButton>
      )}
      {variant === 'secondary' && (
        <SecondaryButton {...attributes}>{label}</SecondaryButton>
      )}
      {variant === 'contact' && (
        <ContactButton
          {...attributes}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {label}
        </ContactButton>
      )}
    </>
  );
}

Button.defaultProps = {
  variant: VARIANTS.primary,
  size: 'md',
  verticalAlignment: false,
};

export default Button;
