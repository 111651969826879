import { Component, ReactNode } from 'react';
import StyledContainer from './styles';

type CustomProps = {
  align?: 'center' | 'flex-start' | 'flex-end';
  className?: string;
  children: ReactNode;
  desktopOnly?: boolean;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  fontFamily?: 'primary' | 'primaryWide' | 'secondary';
  fullHeight?: boolean;
  fullScreenWidth?: boolean;
  fullWidth?: boolean;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  mobileOnly?: boolean;
  responsive?: boolean;
  spaceGap?: number;
  columnGap?: number;
  rowGap?: number;
};

class Container extends Component<CustomProps> {
  render(): JSX.Element {
    return (
      <>
        <StyledContainer
          align={this.props.align}
          flexDirection={this.props.flexDirection}
          justifyContent={this.props.justifyContent}
          mobileOnly={this.props.mobileOnly}
          desktopOnly={this.props.desktopOnly}
          fullHeight={this.props.fullHeight}
          fullWidth={this.props.fullWidth}
          fullScreenWidth={this.props.fullScreenWidth}
          fontFamily={this.props.fontFamily}
          spaceGap={this.props.spaceGap}
          columnGap={this.props.columnGap}
          rowGap={this.props.rowGap}
          responsive={this.props.responsive}
          className={this.props.className}
        >
          {this.props.children}
        </StyledContainer>
      </>
    );
  }
}

export default Container;
