import StyledSeparator from './styles';

type Props = {
  className?: string;
  color: string;
  marginTop: number;
  marginBottom: number;
};

function Separator(props: Props): JSX.Element {
  const {
    className, marginTop, marginBottom, color,
  } = props;
  return (
    <StyledSeparator
      className={className}
      color={color}
      marginBottom={marginBottom}
      marginTop={marginTop}
    ></StyledSeparator>
  );
}

Separator.defaultProps = {
  color: 'transparent',
  marginTop: 0,
  marginBottom: 0,
};

export default Separator;
