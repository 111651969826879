import img01 from 'resources/projects/informe-telefonica-2023/informe-01.jpg';
import img02 from 'resources/projects/informe-telefonica-2023/informe-02.jpg';
import img03 from 'resources/projects/informe-telefonica-2023/informe-03.jpg';
import img04 from 'resources/projects/informe-telefonica-2023/informe-04.jpg';
import img05 from 'resources/projects/informe-telefonica-2023/informe-05.jpg';
import img06 from 'resources/projects/informe-telefonica-2023/informe-06.jpg';
import img07 from 'resources/projects/informe-telefonica-2023/informe-07.jpg';
import img08 from 'resources/projects/informe-telefonica-2023/informe-08.jpg';
import img09 from 'resources/projects/informe-telefonica-2023/informe-09.jpg';
import img10 from 'resources/projects/informe-telefonica-2023/informe-10.jpg';
import img11 from 'resources/projects/informe-telefonica-2023/informe-11.jpg';
// images.js
const images = [
  {
    id: 1,
    src: img01,
    title: 'Informe Telefonica 2023 - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Informe Telefonica 2023 - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Informe Telefonica 2023 - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Informe Telefonica 2023 - Imagen 4',
  },
  {
    id: 5,
    src: img05,
    title: 'Informe Telefonica 2023 - Imagen 5',
  },
  {
    id: 6,
    src: img06,
    title: 'Informe Telefonica 2023 - Imagen 6',
  },
  {
    id: 7,
    src: img07,
    title: 'Informe Telefonica 2023 - Imagen 7',
  },
  {
    id: 8,
    src: img08,
    title: 'Informe Telefonica 2023 - Imagen 8',
  },
  {
    id: 9,
    src: img09,
    title: 'Informe Telefonica 2023 - Imagen 9',
  },
  {
    id: 10,
    src: img10,
    title: 'Informe Telefonica 2023 - Imagen 10',
  },
  {
    id: 11,
    src: img11,
    title: 'Informe Telefonica 2023 - Imagen 11',
  },
];

export default images;
