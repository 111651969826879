import TextLine from 'components/textLine';
import { StyledHomeHero } from './styles';
import { fontWideNormal } from '../../../../styled/global';

function HomeHero(): JSX.Element {
  return (
    <>
      <StyledHomeHero fontSize={9} dynamicSize={true}>
        <TextLine font={fontWideNormal} text="Bienvenidos" />
      </StyledHomeHero>
    </>
  );
}

export default HomeHero;
