import Container from 'components/container';
import TextLine from 'components/textLine';
import styled, { css } from 'styled-components';
import { fontLight, fontWideMedium, fontWideSemiBold } from 'styled/global';
import MediaBreakpoints from 'styled/mediaBreakpoints';

const ProjectDetailsStyle = css`
  .project-details {
    &__heading {
      ${fontWideMedium}
      font-size: 1.25rem;
      margin-bottom: 0.25rem;
      margin-top: 3rem;
      @media ${MediaBreakpoints.largeUp} {
        margin-top: 5.25rem;
      }
      @media ${MediaBreakpoints.extraLargeUp} {
        margin-top: 6rem;
      }
    }
    &__subheading {
      font-size: 18px;
      line-height: 1.44;
      margin-bottom: 0.5rem;
      @media ${MediaBreakpoints.largeUp} {
        font-size: 1.125rem;
        line-height: 1.36;
      }
    }
    &__body {
      &__heading {
        ${fontWideSemiBold}
        font-size: 1.125rem;
        font-stretch: normal;
        line-height: 1.33;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
      }
      &__content {
        margin-bottom: 1rem;
      }
      &__images {
        margin-top: 2rem;
        > div {
          font-size: 0;
        }
      }
    }
  }
`;

export const StyledCategory = styled(TextLine)`
  ${fontLight}
  font-size: 18px;
  font-style: italic;
  @media ${MediaBreakpoints.largeUp} {
    font-size: 20px;
  }
`;

export const StyledCategoriesContainer = styled(Container)`
  margin-bottom: 2rem;
`;
export default ProjectDetailsStyle;
