import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { darkTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Image from 'components/image';
import Separator from 'components/separator';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function Bestours(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Bestours Viajes ✈️', 'Diseño web');
  });
  return (
    <ProjectDetail heading='Bestours Viajes Web ✈️' categories={['Diseño web']} subheading='Bestours Viajes, realizado en KREAB &nbsp;|&nbsp; 2021' theme={darkTheme} setTheme={props.setTheme}>
      <div className="project-details__body__images">
        {images.map(({ id, src, title }) => (
          <React.Fragment key={id}>
            <Image imgSrc={src} imgAlt={title} animationDirection={AnimationDirection.Up}/>
            <Separator marginTop={1} marginBottom={1}></Separator>
          </React.Fragment>
        ))}
      </div>
    </ProjectDetail>
  );
}
