import { DefaultTheme } from 'styled-components';
import { palette } from './constants';

export enum ThemeType {
  GradientColor,
  GradientLight,
  White,
  Dark,
  Purple,
}

export const colorTheme: DefaultTheme = {
  primary: {
    textColor: palette.negro,
  },
  secondary: {
    textColor: palette.blanco,
  },
  type: ThemeType.GradientColor,
};

export const lightTheme: DefaultTheme = {
  primary: {
    textColor: palette.negro,
  },
  secondary: {
    textColor: palette.blanco,
  },
  type: ThemeType.GradientLight,
};

export const whiteTheme: DefaultTheme = {
  primary: {
    textColor: palette.negro,
  },
  secondary: {
    textColor: palette.blanco,
  },
  type: ThemeType.White,
};

export const purpleTheme: DefaultTheme = {
  primary: {
    textColor: palette.negro,
  },
  secondary: {
    textColor: palette.blanco,
  },
  type: ThemeType.Purple,
};

export const darkTheme: DefaultTheme = {
  primary: {
    textColor: palette.blanco,
  },
  secondary: {
    textColor: palette.negro,
  },
  type: ThemeType.Dark,
};
