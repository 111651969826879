import styled from 'styled-components';
import { desktopMaxWidth, deviceSizes } from '../../../../styled/constants';
import MediaBreakpoints from '../../../../styled/mediaBreakpoints';

type StyleProps = {
  color?: string;
  dynamicSize: boolean;
  fontSize: number;
};

export const IconStyled = styled.img`
  bottom: 0;
  display: block;
  position: absolute;
  right: 51%;
  width: 100px;
`;

export const StyledHomeHero = styled.div<StyleProps>`
  align-self: center;
  background-color: ${(props) => props.color || 'transparent'};
  max-height: 100%;
  max-width: 375px;
  font-size: 3rem;
  @media ${MediaBreakpoints.smallUp} {
    font-size: 4rem;
    max-width: ${deviceSizes.small}px;
  }
  @media ${MediaBreakpoints.largeUp} {
    font-size: 7rem;
    max-width: ${deviceSizes.large}px;
  }
  @media ${MediaBreakpoints.extraLargeUp} {
    max-width: ${desktopMaxWidth};
  }
  text-align: center;
  width: 100vw;
`;
