import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { whiteTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Image from 'components/image';
import Separator from 'components/separator';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function CollaCastelleraMadrid(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Colla Castellera Madrid 😻', 'Identidad visual');
  });
  return (
    <ProjectDetail heading='Colla Castellera Madrid 😻' categories={['Identidad visual']} subheading='CCM &nbsp;|&nbsp; 2020' theme={whiteTheme} setTheme={props.setTheme}>
      <div className="project-details__body__images">
        {images.map(({ id, src, title }) => (
          <React.Fragment key={id}>
            <Image imgSrc={src} imgAlt={title} animationDirection={AnimationDirection.Up}/>
            <Separator marginTop={1} marginBottom={1}></Separator>
          </React.Fragment>
        ))}
      </div>
    </ProjectDetail>
  );
}
