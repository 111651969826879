import styled from 'styled-components';
import { ThemeType } from 'styled/theme';
import MediaBreakpoints from '../../../../styled/mediaBreakpoints';

type StyleProps = {
  open: boolean;
};

const StyledBurger = styled.button<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  @media ${MediaBreakpoints.largeUp} {
    display: none;
  }

  span {
    width: 2rem;
    height: 0.125rem;
    background-color: ${(props) => {
    if (props.open && props.theme.type === ThemeType.Dark) {
      return props.theme.secondary.textColor;
    }
    return props.theme.primary.textColor;
  }};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${(props) => (props.open ? '0' : '1')};
      transform: ${(props) => (props.open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
export default StyledBurger;
