import { ReactNode } from 'react';
import { StyledHeading1, StyledHeading2, StyledHeading3 } from './styles';

export type Props = {
  /**
   * Heading content
   */
  children?: ReactNode;
  className?: string;
  label: string;
  level?: 1 | 2 | 3;
  alignment: 'left' | 'center' | 'right';
  marginTop?: number;
  marginBottom?: number;
};

function Heading(props: Props): JSX.Element {
  const {
    className, label, level, alignment, marginBottom, marginTop, children,
  } = props;
  return (
    <>
      {level === 1 && (
        <StyledHeading1
          className={className}
          alignment={alignment}
          marginTop={marginTop}
          marginBottom={marginBottom}
        >
          {label} {children}
        </StyledHeading1>
      )}
      {level === 2 && (
        <StyledHeading2
          className={className}
          alignment={alignment}
          marginTop={marginTop}
          marginBottom={marginBottom}
        >
          {label} {children}
        </StyledHeading2>
      )}
      {level === 3 && (
        <StyledHeading3
          className={className}
          alignment={alignment}
          marginTop={marginTop}
          marginBottom={marginBottom}
        >
          {label} {children}
        </StyledHeading3>
      )}
    </>
  );
}

Heading.defaultProps = {
  className: '',
  level: 1,
  alignment: 'left',
};

export default Heading;
