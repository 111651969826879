import img01 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-01.png';
import img02 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-02.png';
import img03 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-03.png';
import img04 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-04.png';
import img05 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-05.png';
import img06 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-06.png';
import img07 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-07.png';
import img08 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-08.png';
import img09 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-09.png';
import img10 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-10.png';
import img11 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-11.png';
import img12 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-12.png';
import img13 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-13.png';
import img14 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-14.png';
import img15 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-15.png';
import img16 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-16.png';
import img17 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-17.png';
import img18 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-18.png';
import img19 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-19.png';
import img20 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-20.png';
import img21 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-21.png';
import img22 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-22.png';
import img23 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-23.png';
import img24 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-24.png';
import img25 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-25.png';
import img26 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-26.png';
import img27 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-27.png';
import img28 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-28.png';
import img29 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-29.png';
import img30 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-30.png';
import img31 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-31.png';
import img32 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-32.png';
import img33 from 'resources/projects/inteligencia-artificial/inteligencia-artificial-33.png';

const images = [
  {
    id: 1,
    src: img01,
    title: 'Inteligencia Artificial - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Inteligencia Artificial - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Inteligencia Artificial - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Inteligencia Artificial - Imagen 4',
  },
  {
    id: 5,
    src: img05,
    title: 'Inteligencia Artificial - Imagen 5',
  },
  {
    id: 6,
    src: img06,
    title: 'Inteligencia Artificial - Imagen 6',
  },
  {
    id: 7,
    src: img07,
    title: 'Inteligencia Artificial - Imagen 7',
  },
  {
    id: 8,
    src: img08,
    title: 'Inteligencia Artificial - Imagen 8',
  },
  {
    id: 9,
    src: img09,
    title: 'Inteligencia Artificial - Imagen 9',
  },
  {
    id: 10,
    src: img10,
    title: 'Inteligencia Artificial - Imagen 10',
  },
  {
    id: 11,
    src: img11,
    title: 'Inteligencia Artificial - Imagen 11',
  },
  {
    id: 12,
    src: img12,
    title: 'Inteligencia Artificial - Imagen 12',
  },
  {
    id: 13,
    src: img13,
    title: 'Inteligencia Artificial - Imagen 13',
  },
  {
    id: 14,
    src: img14,
    title: 'Inteligencia Artificial - Imagen 14',
  },
  {
    id: 15,
    src: img15,
    title: 'Inteligencia Artificial - Imagen 15',
  },
  {
    id: 16,
    src: img16,
    title: 'Inteligencia Artificial - Imagen 16',
  },
  {
    id: 17,
    src: img17,
    title: 'Inteligencia Artificial - Imagen 17',
  },
  {
    id: 18,
    src: img18,
    title: 'Inteligencia Artificial - Imagen 18',
  },
  {
    id: 19,
    src: img19,
    title: 'Inteligencia Artificial - Imagen 19',
  },
  {
    id: 20,
    src: img20,
    title: 'Inteligencia Artificial - Imagen 20',
  },
  {
    id: 21,
    src: img21,
    title: 'Inteligencia Artificial - Imagen 21',
  },
  {
    id: 22,
    src: img22,
    title: 'Inteligencia Artificial - Imagen 22',
  },
  {
    id: 23,
    src: img23,
    title: 'Inteligencia Artificial - Imagen 23',
  },
  {
    id: 24,
    src: img24,
    title: 'Inteligencia Artificial - Imagen 24',
  },
  {
    id: 25,
    src: img25,
    title: 'Inteligencia Artificial - Imagen 25',
  },
  {
    id: 26,
    src: img26,
    title: 'Inteligencia Artificial - Imagen 26',
  },
  {
    id: 27,
    src: img27,
    title: 'Inteligencia Artificial - Imagen 27',
  },
  {
    id: 28,
    src: img28,
    title: 'Inteligencia Artificial - Imagen 28',
  },
  {
    id: 29,
    src: img29,
    title: 'Inteligencia Artificial - Imagen 29',
  },
  {
    id: 30,
    src: img30,
    title: 'Inteligencia Artificial - Imagen 30',
  },
  {
    id: 31,
    src: img31,
    title: 'Inteligencia Artificial - Imagen 31',
  },
  {
    id: 32,
    src: img32,
    title: 'Inteligencia Artificial - Imagen 32',
  },
  {
    id: 33,
    src: img33,
    title: 'Inteligencia Artificial - Imagen 33',
  },
];

export default images;
