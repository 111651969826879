import { Component, Dispatch, SetStateAction } from 'react';
import StyledBurger from './styles';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

class Burger extends Component<Props> {
  render(): JSX.Element {
    const { setOpen, open } = this.props;

    return (
      <StyledBurger
        aria-label="Toggle menu"
        aria-expanded={open}
        open={open}
        onClick={() => setOpen(!open)}
      >
        <span />
        <span />
        <span />
      </StyledBurger>
    );
  }
}

export default Burger;
