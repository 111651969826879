import PageWrapper from 'components/pageWrapper';
import { DefaultTheme } from 'styled-components';
import { useEffect } from 'react';
import { PortfolioItemType } from 'pages/portfolio/components/item';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { publish } from 'utils/events';
import HomeHero from 'pages/home/components/hero';
import { useLocation } from 'react-router-dom';
import { useDocumentTitle } from 'utils/pageUtils';
import pageTitles from 'utils/constants';
import PortadaErres from '../../resources/projects/portadas/portada-erres.png';
import PortadaCollaCastelleraMadrid from '../../resources/projects/portadas/portada-colla-castellera-madrid.png';
import PortadaValientes from '../../resources/projects/portadas/portada-valientes.png';
import PortadaLaHendija from '../../resources/projects/portadas/portada-hendija.png';
import PortadaVocesX from '../../resources/projects/portadas/portada-voces-x.png';
import PortadaEnfermera from '../../resources/projects/portadas/portada-enfermera.jpg';
import PortadaInteligenciaArtificial from '../../resources/projects/portadas/portada-inteligencia-artificial.png';
import PortadaTallerDeCanto from '../../resources/projects/portadas/portada-tallerdecanto.jpg';
import PortadaTendenciasDigitales2023 from '../../resources/projects/portadas/portada-tendencias-digitales-2023.png';
import PortadaRealState from '../../resources/projects/portadas/portada-realstate.png';
import PortadaBestours from '../../resources/projects/portadas/portada-bestours.png';
import PortadaRandomPosters from '../../resources/projects/portadas/portada-random-posters.png';
import PortadaInformeTelefonica2023 from '../../resources/projects/portadas/portada-informe-telefonica-2023.jpg';
import PortadaCamalotal from '../../resources/projects/portadas/portada-camalotal.png';
import { colorTheme } from '../../styled/theme';
import {
  PortfolioStyle,
  StyledContainer, StyledHomeDesktopWrapper, StyledHomeMobileWrapper,
  StyledHorizontalWrapper, StyledItem, StyledItemWrapper, StyledMobileWrapper,
} from './styles';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function Portfolio(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitle(pageTitles.portfolio);
    props.setTheme(colorTheme);
    publish('pageChange', { name: 'portfolio' });
  });
  const location = useLocation();
  const state = location.state as { internalRedirect?: boolean };

  const carouselItems: Array<PortfolioItemType> = [
    {
      title: 'Camalotal',
      subtitle: 'Branding',
      image: PortadaCamalotal,
      link: '/camalotal',
      emoji: '🌸',
    },
    {
      title: 'Informe de Contribución Socioeconómica',
      subtitle: 'Diseño editorial',
      image: PortadaInformeTelefonica2023,
      link: '/informe-telefonica-2023',
      emoji: '🍃',
    },
    {
      title: 'Voces X la FP Dual',
      subtitle: 'Branding',
      image: PortadaVocesX,
      link: '/voces-x',
      emoji: '📓',
    },
    {
      title: '/erres/',
      subtitle: 'Branding',
      image: PortadaErres,
      link: '/branding-erres',
      emoji: '🌽',
    },
    {
      title: 'IA',
      subtitle: 'Inteligencia Artificial',
      image: PortadaInteligenciaArtificial,
      link: '/inteligencia-artificial',
      emoji: '🌈',
    },
    {
      title: 'Random posters',
      subtitle: 'Just for fun',
      image: PortadaRandomPosters,
      link: '/random-posters',
      emoji: '🎉',
    },
    {
      title: 'Bestours Viajes',
      subtitle: 'Diseño web',
      image: PortadaBestours,
      link: '/diseño-web-bestours-viajes',
      emoji: '✈️',
    },
    {
      title: 'Tendencias Digitales 2023',
      subtitle: 'Diseño editorial',
      image: PortadaTendenciasDigitales2023,
      link: '/informe-tendencias-digitales-2023',
      emoji: '🤖',
    },
    {
      title: 'Paloma Del Cerro',
      subtitle: 'Identidad visual',
      image: PortadaTallerDeCanto,
      link: '/look-and-feel-taller-de-canto',
      emoji: '✨',
    },
    {
      title: 'Tu enfermera\nde la suerte',
      subtitle: 'Identidad visual',
      image: PortadaEnfermera,
      link: '/identidad-visual-tu-enfermera-de-la-suerte',
      emoji: '💉',
    },
    {
      title: 'Catálogo La Hendija',
      subtitle: 'Diseño editorial | Dirección de arte',
      image: PortadaLaHendija,
      link: '/catalogo-editorial-la-hendija',
      emoji: '📓',
    },
    {
      title: 'Colla Castellera de Madrid',
      subtitle: 'Identidad visual',
      image: PortadaCollaCastelleraMadrid,
      link: '/identidad-visual-colla-castellera-madrid',
      emoji: '😻',
    },
    {
      title: 'Valientes',
      subtitle: 'Motion graphics',
      image: PortadaValientes,
      link: '/motion-graphics-valientes',
      emoji: '🪐',
    },
    {
      title: 'Real State',
      subtitle: 'Diseño web',
      image: PortadaRealState,
      link: '/diseño-web-real-state',
      emoji: '🏢',
    },
  ];

  return (
    <PageWrapper heightAuto={true} cssStyling={PortfolioStyle}>
      {/* Mobile */}
      <StyledMobileWrapper>
        { state?.internalRedirect
          ? null : <StyledHomeMobileWrapper fullScreenWidth={true} fullWidth={true}>
            <HomeHero />
          </StyledHomeMobileWrapper>
        }
        {carouselItems.map((item, index) => (
          <StyledItemWrapper key={`Project ${index}`} direction={AnimationDirection.Up}>
            <StyledItem
              title={item.title}
              subtitle={item.subtitle}
              image={item.image}
              link={item.link}
              emoji={item.emoji}
            />
          </StyledItemWrapper>
        ))}
      </StyledMobileWrapper>
      {/* Desktop */}
      <StyledContainer flexDirection="row" spaceGap={4} fullWidth={true}>
        <StyledHorizontalWrapper config={{ stiffness: 100, damping: 35 }} reverseScroll={true} className={state?.internalRedirect ? 'home-included' : ''}>
          {
            state?.internalRedirect
              ? null : <StyledHomeDesktopWrapper fullHeight={true} fullScreenWidth={true} fullWidth={true}>
                <HomeHero />
              </StyledHomeDesktopWrapper>
          }

          {carouselItems.map((item, index) => (
            <StyledItemWrapper key={`Project ${index}`} direction={AnimationDirection.Left}>
              <StyledItem
                title={item.title}
                subtitle={item.subtitle}
                image={item.image}
                link={item.link}
                emoji={item.emoji}
              />
            </StyledItemWrapper>
          ))}
        </StyledHorizontalWrapper>
      </StyledContainer>
    </PageWrapper>
  );
}
