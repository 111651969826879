import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { fontNormal, fontWideNormal } from '../../styled/global';

interface StyleProps {
  size?: 'sm' | 'md' | 'lg';
  $verticalAlignment: boolean;
}

const ButtonBaseStyles = styled(Link)<StyleProps>`
  ${fontWideNormal}
  border: solid 1.5px;
  border-radius: 8px;
  box-shadow: none;
  font-size: ${(props) => {
    if (props.size === 'sm') return '0.75rem';
    if (props.size === 'md') return '1rem';
    if (props.size === 'lg') return '1.25rem';
    return '1rem';
  }};
  padding: ${(props) => {
    if (props.size === 'sm') return '0.5rem';
    if (props.size === 'md') return '0.66rem 1rem';
    if (props.size === 'lg') return '1.25rem';
    return '0.75rem';
  }};
  text-decoration: none;
  ${(props) => {
    if (props.$verticalAlignment) {
      return `
        margin-top: auto;
        margin-bottom: auto;
      `;
    }
    return '';
  }};
`;

export const PrimaryButton = styled(ButtonBaseStyles)`
  background-color: "transparent";
  color: ${(props) => props.theme.primary.textColor};
  border-color: ${(props) => props.theme.primary.textColor};
`;

export const SecondaryButton = styled(ButtonBaseStyles)`
  background-color: "transparent";
  color: ${(props) => props.theme.secondary.textColor};
  border-color: ${(props) => props.theme.secondary.textColor};
`;

export const ContactButton = styled.a<StyleProps>`
  color: ${(props) => props.theme.primary.textColor};
  ${fontNormal};
  font-size: ${(props) => {
    if (props.size === 'sm') return '0.75rem';
    if (props.size === 'md') return '1rem';
    if (props.size === 'lg') return '1.25rem';
    return '1rem';
  }};
  text-decoration: none;
`;
