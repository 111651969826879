import styled from 'styled-components';

type StyleProps = {
  marginTop: number;
  marginBottom: number;
  color: string;
};

const StyledSeparator = styled.hr<StyleProps>`
  border-bottom: 0;
  border-top: 2px solid ${(props) => props.color};
  color: transparent;
  margin-top: ${(props) => props.marginTop}em;
  margin-bottom: ${(props) => props.marginBottom}em;
  width: 100%;
`;

export default StyledSeparator;
