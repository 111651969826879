import img01 from 'resources/projects/colla-castellera-madrid/colla-castellera-madrid-01.jpg';
import img02 from 'resources/projects/colla-castellera-madrid/colla-castellera-madrid-02.jpg';
import img03 from 'resources/projects/colla-castellera-madrid/colla-castellera-madrid-03.jpg';
import img04 from 'resources/projects/colla-castellera-madrid/colla-castellera-madrid-04.jpg';

const images = [
  {
    id: 1,
    src: img01,
    title: 'Tendencias Digitales 2023 - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Tendencias Digitales 2023 - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Tendencias Digitales 2023 - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Tendencias Digitales 2023 - Imagen 4',
  },
];

export default images;
