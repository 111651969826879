import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { whiteTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Heading from 'components/heading';
import Image from 'components/image';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function InformeTelefonica2023(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Informe de Contribución Socioeconómica 🍃', 'Diseño editorial');
  });
  return (
    <ProjectDetail heading='Informe de Contribución Socioeconómica 🍃' categories={['Diseño editorial']} subheading='Telefónica, realizado en KREAB &nbsp;|&nbsp; 2023' theme={whiteTheme} setTheme={props.setTheme}>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Punto de partida"
        level={2}
        marginBottom={0.5}
      />
      <div>
        <p>
          En el contexto actual, la medición y cuantificación del impacto se han convertido en herramientas clave para
          mejorar la gestión, divulgar la sostenibilidad, fortalecer la relación con los grupos de interés y desarrollar
          ventajas competitivas. Es en este marco que Telefónica, desde 2019, ha llevado a cabo diversos estudios sobre
          los impactos sociales y medioambientales derivados de su actividad.
        </p>
      </div>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Requerimientos del proyecto"
        level={2}
        marginBottom={0.5}
      />
      <div>
        <p>
          Realizar la maquetación del informe de Contribución Socioeconómica tomando como punto de partida las brand
          guides del cliente. Para ello, desarrollamos una serie de contenidos infográficos destinados a facilitar la
          comprensión del informe, brindando una visualización clara y efectiva del contenido.
        </p>
      </div>
      <div className="project-details__body__images">
        {images.map(({ id, src, title }) => (
          <React.Fragment key={id}>
            <Image imgSrc={src} imgAlt={title} animationDirection={AnimationDirection.Up}/>
          </React.Fragment>
        ))}
      </div>
    </ProjectDetail>
  );
}
