import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import { useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import ScrollToTop from 'utils/scrollToTop';
import Portfolio from 'pages/portfolio';
import About from 'pages/about';
import Header from 'components/structure/Header';
import GlobalStyle from 'styled/global';
import LaHendija from 'pages/projects/la-hendija';
import Valientes from 'pages/projects/valientes';
import Erres from 'pages/projects/erres';
import CollaCastelleraMadrid from 'pages/projects/colla-castellera-madrid';
import TallerDeCanto from 'pages/projects/taller-de-canto';
import Enfermera from 'pages/projects/enfermera';
import BackToTop from 'components/back-to-top';
import TendenciasDigitals2023 from 'pages/projects/tendencias-digitales-2023';
import InteligenciaArtificial from 'pages/projects/inteligencia-artificial';
import RealEstate from 'pages/projects/real-state';
import Bestours from 'pages/projects/bestours';
import RandomPosters from 'pages/projects/random-posters';
import InformeTelefonica2023 from 'pages/projects/informe-telefonica-2023';
import VocesX from 'pages/projects/voces-x';
import Camalotal from 'pages/projects/camalotal';
import { StyledApp, StyledSeparator, StyledWrapper } from './styles';
import { colorTheme } from '../../styled/theme';

type Props = {
  setTheme:React.Dispatch<React.SetStateAction<DefaultTheme>>
};
function NavigationHandler(props: Props): JSX.Element {
  return (
    <Routes>
      <Route path="/about" element={<About setTheme={props.setTheme} />}>
      </Route>
      <Route path="/portfolio" element={<Portfolio setTheme={props.setTheme} />}>
      </Route>
      <Route path="/camalotal" element={<Camalotal setTheme={props.setTheme} />}>
      </Route>
      <Route path="/informe-tendencias-digitales-2023" element={<TendenciasDigitals2023 setTheme={props.setTheme} />}>
      </Route>
      <Route path="/informe-telefonica-2023" element={<InformeTelefonica2023 setTheme={props.setTheme} />}>
      </Route>
      <Route path="/voces-x" element={<VocesX setTheme={props.setTheme} />}>
      </Route>
      <Route path="/motion-graphics-valientes" element={<Valientes setTheme={props.setTheme} />}>
      </Route>
      <Route path="/identidad-visual-colla-castellera-madrid" element={<CollaCastelleraMadrid setTheme={props.setTheme} />}>
      </Route>
      <Route path="/branding-erres" element={<Erres setTheme={props.setTheme} />}>
      </Route>
      <Route path="/identidad-visual-tu-enfermera-de-la-suerte" element={<Enfermera setTheme={props.setTheme} />}>
      </Route>
      <Route path="/look-and-feel-taller-de-canto" element={<TallerDeCanto setTheme={props.setTheme} />}>
      </Route>
      <Route path="/catalogo-editorial-la-hendija" element={<LaHendija setTheme={props.setTheme} />}>
      </Route>
      <Route path="/inteligencia-artificial" element={<InteligenciaArtificial setTheme={props.setTheme} />}>
      </Route>
      <Route path="/diseño-web-real-state" element={<RealEstate setTheme={props.setTheme} />}>
      </Route>
      <Route path="/diseño-web-bestours-viajes" element={<Bestours setTheme={props.setTheme} />}>
      </Route>
      <Route path="/random-posters" element={<RandomPosters setTheme={props.setTheme} />}>
      </Route>
      <Route path="/*" element={<Portfolio setTheme={props.setTheme} />}>
      </Route>
    </Routes>);
}

function App(): JSX.Element {
  const [theme, setTheme] = useState(colorTheme);
  const [navMenuOpen, setNavMenuOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <StyledWrapper>
        <GlobalStyle menuOpen={navMenuOpen} />
        <Router>
          <Header menuOpen={navMenuOpen} setMenuOpen={setNavMenuOpen} />
          <StyledSeparator color={theme.primary.textColor} />
          <StyledApp>
            <ScrollToTop />
            <BackToTop />
            <NavigationHandler setTheme={setTheme}/>
          </StyledApp>
        </Router>
      </StyledWrapper>
    </ThemeProvider>
  );
}

export default App;
