import Image from 'components/image';
import Container from 'components/container';
import TextLine from 'components/textLine';
import { fontWideNormal } from '../../../../styled/global';
import { FontSize } from '../../../../styled/constants';
import { StyledHeading, StyledWrapper, StyledEmoji } from './styles';

export type PortfolioItemType = {
  title: string;
  subtitle: string;
  image: string;
  link: string;
  emoji: string;
};

type Props = {
  className?: string;
  image: string;
  imageAlt?: string;
  link?: string;
  subtitle?: string;
  title: string;
  emoji: string;
};

function PortfolioItem(props: Props): JSX.Element {
  const {
    title, subtitle, image, imageAlt, link, className, emoji,
  } = props;
  return (
    <>
      <StyledWrapper href={link} cursorEmoji={emoji}>
        <Container flexDirection="column" className={className} spaceGap={1}>
          <Container className={className} spaceGap={0}>
            <Image imgAlt={imageAlt || ''} imgSrc={image} className='portfolio-item__image' disableLazyLoad={true}/>
          </Container>
          <Container flexDirection="column" className={className} spaceGap={1} justifyContent='center'>
            <Container flexDirection="column" className={className} spaceGap={0.5} justifyContent='flex-start'>
              <StyledHeading
                label={title}
                level={2}
                alignment='center'
                className='portfolio-item__heading'>
                <StyledEmoji>{emoji}</StyledEmoji>
              </StyledHeading>
              <TextLine alignment='center' size={FontSize.m} text={subtitle || ''} font={fontWideNormal} noBreak={true}/>
            </Container>
          </Container>
        </Container>
      </StyledWrapper>
    </>
  );
}

export default PortfolioItem;
