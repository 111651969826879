import img01 from 'resources/projects/la-hendija/01.jpg';
import img02 from 'resources/projects/la-hendija/02.jpg';
import img03 from 'resources/projects/la-hendija/03.jpg';
import img04 from 'resources/projects/la-hendija/04.jpg';
import img05 from 'resources/projects/la-hendija/05.jpg';
import img06 from 'resources/projects/la-hendija/06.jpg';
import img07 from 'resources/projects/la-hendija/07.jpg';
import img08 from 'resources/projects/la-hendija/08.jpg';
import img09 from 'resources/projects/la-hendija/09.jpg';
import img10 from 'resources/projects/la-hendija/10.jpg';
import img11 from 'resources/projects/la-hendija/11.jpg';
import img12 from 'resources/projects/la-hendija/12.jpg';
import img13 from 'resources/projects/la-hendija/13.jpg';
import img15 from 'resources/projects/la-hendija/15.jpg';
import img16 from 'resources/projects/la-hendija/16.jpg';
import img17 from 'resources/projects/la-hendija/17.jpg';
import img18 from 'resources/projects/la-hendija/18.jpg';
import img19 from 'resources/projects/la-hendija/19.jpg';
import img20 from 'resources/projects/la-hendija/20.jpg';
import img21 from 'resources/projects/la-hendija/21.jpg';
// images.js
const images = [
  {
    id: 1,
    src: img01,
    title: 'La Hendija - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'La Hendija - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'La Hendija - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'La Hendija - Imagen 4',
  },
  {
    id: 5,
    src: img05,
    title: 'La Hendija - Imagen 5',
  },
  {
    id: 6,
    src: img06,
    title: 'La Hendija - Imagen 6',
  },
  {
    id: 7,
    src: img07,
    title: 'La Hendija - Imagen 7',
  },
  {
    id: 8,
    src: img08,
    title: 'La Hendija - Imagen 8',
  },
  {
    id: 9,
    src: img09,
    title: 'La Hendija - Imagen 9',
  },
  {
    id: 10,
    src: img10,
    title: 'La Hendija - Imagen 10',
  },
  {
    id: 11,
    src: img11,
    title: 'La Hendija - Imagen 11',
  },
  {
    id: 12,
    src: img12,
    title: 'La Hendija - Imagen 12',
  },
  {
    id: 13,
    src: img13,
    title: 'La Hendija - Imagen 13',
  },
  {
    id: 15,
    src: img15,
    title: 'La Hendija - Imagen 15',
  },
  {
    id: 16,
    src: img16,
    title: 'La Hendija - Imagen 16',
  },
  {
    id: 17,
    src: img17,
    title: 'La Hendija - Imagen 17',
  },
  {
    id: 18,
    src: img18,
    title: 'La Hendija - Imagen 18',
  },
  {
    id: 19,
    src: img19,
    title: 'La Hendija - Imagen 19',
  },
  {
    id: 20,
    src: img20,
    title: 'La Hendija - Imagen 20',
  },
  {
    id: 21,
    src: img21,
    title: 'La Hendija - Imagen 21',
  },
];

export default images;
