import styled, { CSSProp } from 'styled-components';
import MediaBreakpoints from '../../styled/mediaBreakpoints';
import { deviceSizes, fixedSizes } from '../../styled/constants';

type StyleProps = {
  cssStyling?: CSSProp;
  heightAuto?: boolean;
};

const StyledWrapper = styled.div<StyleProps>`
  width: 375px;
  @media ${MediaBreakpoints.smallUp} {
    width: ${deviceSizes.small}px;
  }
  @media ${MediaBreakpoints.mediumUp} {
    width: ${deviceSizes.medium}px;
  }
  @media ${MediaBreakpoints.largeUp} {
    height: ${(props) => {
    if (props.heightAuto) {
      return 'auto';
    }
    return `
            calc(
              100vh - ${fixedSizes.bodyVerticalPadding} * 2 -
                ${fixedSizes.headerHeightLg}
            );
          `;
  }};
    width: ${deviceSizes.medium}px;
  }
  @media ${MediaBreakpoints.extraLargeUp} {
    width: ${deviceSizes.extraLarge}px;
  }

  ${(props) => props.cssStyling}
`;

export default StyledWrapper;
