import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import Nav from 'components/structure/Header/Nav';

import {
  Header as StyledHeader,
  StyledDiv,
  StyledHeading as Heading,
  StyledWrapper,
} from './styles';

interface Props {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

function Header(props: Props): JSX.Element {
  return (
    <StyledHeader>
      <StyledWrapper>
        <StyledDiv>
          <Link to="/" className="no-decoration">
            <Heading label="Anita Acosta" level={3} />
          </Link>
        </StyledDiv>
        <Nav setMenuOpen={props.setMenuOpen} menuOpen={props.menuOpen} />
      </StyledWrapper>
    </StyledHeader>
  );
}

export default Header;
