import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Heading from 'components/heading';
import StyledSeparator from '../../separator/styles';
import StyledBurger from './Burger/styles';
import MediaBreakpoints from '../../../styled/mediaBreakpoints';
import { altFontNormal, fontWideNormal } from '../../../styled/global';
import {
  palette, fixedSizes, fontWeight, desktopMaxWidth,
} from '../../../styled/constants';

interface Props {
  open?: boolean;
}

export const Nav = styled.nav<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  .nav-list {
    display: none;
    list-style: none;
    margin: 0;
    ${fontWideNormal}
    @media ${MediaBreakpoints.largeUp} {
      display: flex;
      gap: 1rem;
    }
    li {
      margin: 0;
      @media ${MediaBreakpoints.largeUp} {
        margin: 1rem 0;
      }
    }
  }
  &.mobile-nav {
    @media ${MediaBreakpoints.largeUp} {
      display: none;
    }
    .nav-list {
      align-items: center;
      background: linear-gradient(
        to bottom right,
        ${palette.violeta},
        ${palette.blanco}
        );
        display: flex;
        flex-direction: column;
        font-size: 30px;
        height: 100%;
        justify-content: center;
        left: -100vw;
        padding: 80px 26px;
        position: absolute;
        top: 0;
        transition-property: left;
        transition-duration: 0.25s;
        transition-timing-function: linear;
        width: 100%;
      }
      &.opened {
        .nav-list {
          left: 0;
          a {
            color: ${palette.negro}
          }
        }
      }
    }
    `;

export const StyledLateralNav = styled(Nav)`
  height: 100%;
  position: fixed;
  right: 50px;
  transition: opacity 0.5s ease;
  top: 0;
  .nav-list {
    li {
      font-size: 0.75rem;

    }
    writing-mode: vertical-rl;
  }
`;

export const StyledHeading = styled(Heading)`
  color: ${(props) => props.theme.primary.textColor};
  ${altFontNormal}
  font-size: 28px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0 auto 0 auto;
  width: 100%;
`;

export const StyledDiv = styled.div`
  position: relative;
  width: auto;
`;
export const StyledWrapper = styled.div`
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
    flex-flow: row nowrap;
    @media ${MediaBreakpoints.largeUp} {
      align-items: baseline;
    }
`;

export const BaseLink = styled(Link)`
  color: ${(props) => props.theme.primary.textColor};
  display: block;
  padding: 20px 0 10px;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  font-weight: ${fontWeight.light};
  transition: font-weight .5s ease-in-out;

  &.active {
    font-weight: ${fontWeight.medium};
  }
`;

export const NavLink = styled(BaseLink)`
  color: ${(props) => props.theme.primary.textColor};
  display: block;
  padding: 20px 0 10px;
  position: relative;
  text-decoration: none;
  /* Estas animaciones las podría borrar. */
  /* transition-duration: 1000ms;
  transition-property: font-weigth;
  transition-timing-function: ease-in-out; */
  overflow: hidden;
  font-weight: ${fontWeight.light};
  transition: font-weight .5s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 0;
    height: 2px;
    background-color: ${(props) => props.theme.primary.textColor};
    opacity: 0;
    transition: opacity 500ms;
  }
  
  &.active {
    font-weight: ${fontWeight.medium};
  }
  &:hover {
  /* text-decoration: 2px solid ${(props) => props.theme.primary.textColor} underline; */
  /* text-underline-position: under; */
    &::after {
      opacity: 1;
      width: 100%;
    };
  }
  &:hover {
    &::after {
      transition: width 500ms ease-out, opacity 1000ms;
    };
  }
`;

export const Header = styled.header`
  align-items: flex-start;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-flow: row nowrap;
  height: ${fixedSizes.headerHeight};
  margin-bottom: 1rem;
  top: 0;
  max-width: ${desktopMaxWidth};
  width: 100%;
  z-index: 2;
  padding-bottom: 10px;
  ${StyledSeparator} {
    display: none;
  }
  @media ${MediaBreakpoints.largeUp} {
    flex-flow: column wrap;
    height: ${fixedSizes.headerHeightLg};
    justify-content: end;
    left: calc((100vw - ${desktopMaxWidth}) / 2);
    margin-bottom: 0;
    padding-bottom: 0;
    ${StyledSeparator} {
      display: block;
    }
    ${Nav} {
      ${StyledBurger} {
        display: none;
      }
    }
  }
`;
