import img01 from 'resources/projects/random-posters/random-posters-01.png';
import img02 from 'resources/projects/random-posters/random-posters-02.png';
import img03 from 'resources/projects/random-posters/random-posters-03.png';
import img04 from 'resources/projects/random-posters/random-posters-04.png';

const images = [
  {
    id: 1,
    src: img01,
    title: 'Random posters - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Random posters - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Random posters - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Random posters - Imagen 4',
  },
];

export default images;
