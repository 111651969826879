import FadeInSection from 'components/FadeInSection';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { Component } from 'react';
import LazyLoad from 'react-lazyload';
import ConditonalWrapper from 'utils/ConditionalWrapper';
import StyledImage from './styles';

type Props = {
  imgSrc: string;
  imgAlt: string;
  roundedCorners?: boolean;
  className?: string;
  animationDirection?: AnimationDirection;
  disableLazyLoad?: boolean;
  width?: string;
};

class Image extends Component<Props> {
  render(): JSX.Element {
    const {
      imgSrc, imgAlt, roundedCorners, className, animationDirection, disableLazyLoad, width,
    } = this.props;

    return (
      // eslint-disable-next-line max-len
      <ConditonalWrapper wrapper={(childrenAux) => <FadeInSection direction={animationDirection || AnimationDirection.Down}>{childrenAux}</FadeInSection>
      } condition={!!animationDirection}>
        <ConditonalWrapper wrapper={(childrenAux) => <LazyLoad height="500px" once={true} offset={200}>{childrenAux}</LazyLoad>
        } condition={!disableLazyLoad}>
          <StyledImage
            alt={imgAlt}
            className={className}
            roundedCorners={roundedCorners}
            src={`${imgSrc}`}
            width={width || '100%'}
          />
        </ConditonalWrapper>
      </ConditonalWrapper>

    );
  }
}

export default Image;
