import styled from 'styled-components';
import MediaBreakpoints from '../../styled/mediaBreakpoints';
import { deviceSizes, fixedSizes } from '../../styled/constants';
import {
  fontNormal, altFontNormal, fontWideNormal, fontLight,
} from '../../styled/global';

 type Props = {
   flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
   align?: 'center' | 'flex-start' | 'flex-end';
   justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
   responsive?: boolean;
   fullWidth?: boolean;
   fullScreenWidth?: boolean;
   mobileOnly?: boolean;
   desktopOnly?: boolean;
   fullHeight?: boolean;
   fontFamily?: 'primary' | 'primaryWide' | 'secondary';
   spaceGap?: number;
   columnGap?: number;
   rowGap?: number;
 };

const StyledContainer = styled.div<Props>`
  ${(props) => {
    if (props.mobileOnly) {
      return `
      display: flex;
        @media ${MediaBreakpoints.largeUp} {
          display: none;
        }
        `;
    } if (props.desktopOnly) {
      return `
        display: none;
        @media ${MediaBreakpoints.largeUp} {
          display: flex;
        }
        `;
    }
    return 'display: flex;';
  }};
  align-items: ${(props) => props.align};
  ${(props) => {
    if (props.responsive) {
      return `
        flex-direction: column;
        @media ${MediaBreakpoints.largeUp} {
          flex-direction: row;
        }
        `;
    }
    return `
        flex-direction: ${props.flexDirection || 'row'};
      `;
  }};
  ${(props) => {
    if (props.fontFamily === 'primary') {
      return fontNormal;
    } if (props.fontFamily === 'secondary') {
      return altFontNormal;
    } if (props.fontFamily === 'primaryWide') {
      return fontWideNormal;
    }
    return fontLight;
  }};
  column-gap: ${(props) => (props.columnGap !== undefined ? props.columnGap : props.spaceGap)}rem;
  row-gap: ${(props) => (props.rowGap !== undefined ? props.rowGap : props.spaceGap)}rem;
  height: 100%;
  ${(props) => {
    if (props.fullHeight) {
      return `
      min-height: calc(100vh - ${fixedSizes.bodyVerticalPadding} - ${fixedSizes.headerHeight});
        `;
    }
    return '';
  }};
  justify-content: ${(props) => props.justifyContent};
  max-height: 100%;
  ${(props) => (props.fullScreenWidth ? '' : 'max-width: 100%;')};
  position: relative;
  width: 100%;
  @media ${MediaBreakpoints.largeUp} {
    ${(props) => (props.fullWidth ? 'width: 100vw;' : '')};
    ${(props) => (props.fullScreenWidth ? '' : `max-width: ${deviceSizes.medium}px;`)};
  }
  @media ${MediaBreakpoints.extraLargeUp} {
    ${(props) => (props.fullScreenWidth ? '' : `max-width: ${deviceSizes.extraLarge}px;`)};
  }
`;

StyledContainer.defaultProps = {
  flexDirection: 'row',
  align: 'center',
  justifyContent: 'center',
  responsive: false,
  mobileOnly: false,
  desktopOnly: false,
  fullHeight: false,
  spaceGap: 1,
};

export default StyledContainer;
