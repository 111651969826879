import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { whiteTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Heading from 'components/heading';
import Image from 'components/image';
import Separator from 'components/separator';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function RealEstate(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Real estate 🏢', 'Diseño web');
  });
  return (
    <ProjectDetail heading='Real estate web' categories={['Web', 'Look & feel social media']} subheading='Propuesta &nbsp;|&nbsp; 2021' theme={whiteTheme} setTheme={props.setTheme}>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Requerimientos del proyecto"
        level={2}
        marginBottom={0.5}
      />
      <p>
            Propuesta de diseño web y look & feel de redes sociales
            para inmobiliaria especializada en propiedades de lujo.
      </p>
      <br />
      <div className="project-details__body__images">
        {images.map(({ id, src, title }) => (
          <React.Fragment key={id}>
            <Image imgSrc={src} imgAlt={title} animationDirection={AnimationDirection.Up}/>
            <Separator marginTop={1} marginBottom={1}></Separator>
          </React.Fragment>
        ))}
      </div>
    </ProjectDetail>
  );
}
