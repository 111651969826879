import { css, keyframes } from 'styled-components';
import { palette } from './constants';
import MediaBreakpoints from './mediaBreakpoints';

const AnimationDuration = '20s';
const AnimationSteps = keyframes`
  0% {
    background-position: 50% 50%;
  }
  20% {
    background-position: 0% 0%;
  }
  40% {
    background-position: 0% 100%;
  }
  60% {
    background-position: 100% 100%;
  }
  80% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 50% 50%;
  }
`;

const background = css`
  background: radial-gradient(
      farthest-side at bottom left,
      ${palette.verde},
      transparent 75%
    ),
    radial-gradient(
      farthest-corner at bottom right,
      ${palette.amarillo},
      transparent 75%
    ),
    radial-gradient(
      farthest-corner at top right,
      ${palette.blanco},
      transparent 25%
    ),
    radial-gradient(circle at 20% 30%, ${palette.violeta}, transparent);
`;

export const AnimatedBackgroundColor = css`
  ${background}
  background-color: ${palette.blanco};
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: 150% 150%;
  @media ${MediaBreakpoints.largeUp} {
    animation: ${AnimationSteps} ${AnimationDuration} ease infinite;
  }
`;

// Light Theme Background
const backgroundLight = css`
  background: radial-gradient(
      farthest-side at bottom left,
      ${palette.verde},
      transparent 75%
    ),
    radial-gradient(
      farthest-corner at top right,
      ${palette.blanco},
      transparent 25%
    );
`;

export const AnimatedBackgroundLight = css`
  ${backgroundLight}
  background-color: ${palette.blanco};
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: 150% 150%;
  @media ${MediaBreakpoints.largeUp} {
    animation: ${AnimationSteps} ${AnimationDuration} ease infinite;
  }
`;
