import styled from 'styled-components';
import { desktopMaxWidth, FontSize } from '../../styled/constants';

type StyleProps = {
  aligment?: 'left' | 'center' | 'right';
  color?: string;
  font: string;
  size?: FontSize;
};

const StyledTextLine = styled.span<StyleProps>`
  color: ${(props) => {
    if (props.color) {
      return props.color;
    }
    return props.theme.primary.textColor;
  }};

  ${(props) => props.color};
  font-size: ${(props) => {
    if (props.size) {
      return `${props.size.valueOf()}em`;
    }
    return 'inherit';
  }};
  ${(props) => props.font}
  max-width: ${desktopMaxWidth};
  text-align: ${(props) => props?.aligment};
  word-break: break-word;
`;

export default StyledTextLine;
