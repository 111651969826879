import styled from 'styled-components';

type StyleProps = {
  roundedCorners?: boolean;
};

const StyledImage = styled.img<StyleProps>`
  border-radius: ${(props) => (props.roundedCorners ? '50px' : '0')};
`;

export default StyledImage;
