import { Component, ReactNode } from 'react';
import { CSSProp } from 'styled-components';
import StyledWrapper from './styles';

type Props = {
  children: ReactNode;
  cssStyling?: CSSProp;
  heightAuto?: boolean;
};
class PageWrapper extends Component<Props> {
  render(): JSX.Element {
    return (
      <>
        <StyledWrapper
          cssStyling={this.props.cssStyling}
          heightAuto={this.props.heightAuto}
        >
          {this.props.children}
        </StyledWrapper>
      </>
    );
  }
}

export default PageWrapper;
