import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { whiteTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import video01 from 'resources/projects/valientes/01.mp4';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function Valientes(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Valientes 🪐', 'Motion graphics');
  });
  return (
    <ProjectDetail heading='Valientes 🪐' categories={['Motion Graphics']} subheading='Estudio creativo KREAB &nbsp;|&nbsp; 2021' theme={whiteTheme} setTheme={props.setTheme}>
      <video width="100%" controls autoPlay>
        <source src={video01} type="video/mp4"/>
      </video>
    </ProjectDetail>
  );
}
