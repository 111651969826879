import img01 from 'resources/projects/taller-de-canto/tallerdecanto-1.jpg';
import img02 from 'resources/projects/taller-de-canto/tallerdecanto-2.jpg';
import img03 from 'resources/projects/taller-de-canto/tallerdecanto-3.jpg';
import img04 from 'resources/projects/taller-de-canto/tallerdecanto-4.jpg';
import img05 from 'resources/projects/taller-de-canto/tallerdecanto-5.jpg';
import img06 from 'resources/projects/taller-de-canto/tallerdecanto-6.jpg';
import img07 from 'resources/projects/taller-de-canto/tallerdecanto-7.jpg';

const images = [
  {
    id: 1,
    src: img01,
    title: 'Taller de canto - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Taller de canto - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Taller de canto - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Taller de canto - Imagen 4',
  },
  {
    id: 5,
    src: img05,
    title: 'Taller de canto - Imagen 5',
  },
  {
    id: 6,
    src: img06,
    title: 'Taller de canto - Imagen 6',
  },
  {
    id: 7,
    src: img07,
    title: 'Taller de canto - Imagen 7',
  },
];

export default images;
