import PageWrapper from 'components/pageWrapper';
import { DefaultTheme } from 'styled-components';
import { useEffect } from 'react';
import Button from 'components/button';
import Heading from 'components/heading';
import Container from 'components/container';
import Image from 'components/image';
import Separator from 'components/separator';
import { publish } from 'utils/events';
import Img01 from 'resources/about/anita-acosta-1.png';
import Img02 from 'resources/about/anita-acosta-2.png';
import Img03 from 'resources/about/anita-acosta-3.png';
import { useDocumentTitle } from 'utils/pageUtils';
import AboutStyle, { ImagesWrapper } from './styles';
import { colorTheme } from '../../styled/theme';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function About(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitle('Sobre mí');
    props.setTheme(colorTheme);
    publish('pageChange', { name: 'about' });
  });
  return (
    <PageWrapper cssStyling={AboutStyle} heightAuto={true}>
      <Container flexDirection="column">
        <Separator marginTop={5}></Separator>
        {/* Contenido */}
        <Container flexDirection="row" align="flex-start" responsive={true} columnGap={2.5} rowGap={5}>
          {/* Columna Izquierda */}
          <ImagesWrapper flexDirection="column" justifyContent="flex-start" spaceGap={5}>
            <Image
              disableLazyLoad={true}
              imgAlt="Anita Acosta 01"
              imgSrc={Img01}
              width='60%'
            />
            <Image
              disableLazyLoad={true}
              imgAlt="Anita Acosta 02"
              imgSrc={Img02}
              width='60%'
            />
            <Image
              disableLazyLoad={true}
              imgAlt="Anita Acosta 03"
              imgSrc={Img03}
              width='60%'
            />
          </ImagesWrapper>
          {/* Columna Derecha */}
          <Container
            flexDirection="column"
            justifyContent="flex-start"
            fontFamily="primaryWide"
            align='flex-start'
            className='about'
          >
            <p>
            ¡Hola! Soy Anita Acosta, una diseñadora gráfica argentina :)
            </p>
            <p>
            En mi día a día colaboro en diferentes proyectos que requieren conocimiento en áreas como, identidad visual,
            audiovisual, motion graphics, diseño editorial y web.
            </p>

            <p>
            Me gusta emprender proyectos con una mirada original y creativa, pero sobre todo con mucha ilusión.
            Creo que mantener la motivación es la única manera de conseguir los mejores resultados:
            únicos, a veces inesperados, sorprendentes, pero siempre funcionales.
            </p>

            <p>
              En esta web, vas a encontrar una pequeña <strong>selección de proyectos que me representan</strong>.
              Si querés conocer más sobre mi actividad profesional, podés visitar <a href='https://www.linkedin.com/in/anitaacostadg/' target='_blank'>mi perfil</a> de LinkedIn.
            </p>

            <p>
              <strong>¿Quién soy y qué me gusta?</strong>
            </p>

            <p>
              Las <strong>artes plásticas</strong>, la <strong>arquitectura</strong>, la <strong>fotografía</strong> y
              el <strong>cine</strong> siempre han estado muy presentes en mi vida
              y han despertado mi interés desde pequeña.
            </p>

            <p>
              También me gusta mucho <strong>viajar</strong> y <strong>conocer otras culturas</strong>.
              Actualmente vivo en Madrid desde hace más de 5 años,
              pero no descarto la posibilidad de establecerme en otro sitio.
            </p>

            <p>
              Ahora mismo estoy muy entusiasmada con la <strong>generación de imágenes con
              inteligencia artificial</strong>.
              Creo que la IA es una gran aliada para poner a trabajar la <strong>creatividad</strong> y
              nos permite crear mundos infinitos. Aquí podrás encontrar algunos de mis proyectos utilizando MidJourney.
            </p>

            <p>
              Te invito a que des una vuelta por mi web y veas qué te parece ;)
            </p>

            <Separator marginBottom={2} marginTop={2} color={colorTheme.primary.textColor}/>
            <Container flexDirection="column" align="flex-start" justifyContent='flex-start' fontFamily="primaryWide">
              <Heading level={3} label="Let's keep in touch" alignment="left" />
              <Button
                label="anitasacosta@gmail.com"
                link="mailto:anitasacosta@gmail.com?subject=Hola+Anita!"
                variant="contact"
                size='md'
              ></Button>
              <Button
                label="LinkedIn"
                link="https://www.linkedin.com/in/anitaacostadg/"
                variant="contact"
                size='md'
              ></Button>
              <Button
                label="Instagram"
                link="https://www.instagram.com/anitaacostadg"
                variant="contact"
                size='md'
              ></Button>
            </Container>
          </Container>
        </Container>
      </Container>
    </PageWrapper>
  );
}
