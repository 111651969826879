import img00 from 'resources/projects/voces-x/voces-x-00.png';
import img01 from 'resources/projects/voces-x/voces-x-01.jpg';
import img02 from 'resources/projects/voces-x/voces-x-02.jpg';
import img03 from 'resources/projects/voces-x/voces-x-03.jpg';
import img04 from 'resources/projects/voces-x/voces-x-04.jpg';
import img05 from 'resources/projects/voces-x/voces-x-05.jpg';
import img06 from 'resources/projects/voces-x/voces-x-06.jpg';
import img07 from 'resources/projects/voces-x/voces-x-07.jpg';
import img08 from 'resources/projects/voces-x/voces-x-08.jpg';
import img09 from 'resources/projects/voces-x/voces-x-09.jpg';
import img10 from 'resources/projects/voces-x/voces-x-10.jpg';
import img11 from 'resources/projects/voces-x/voces-x-11.jpg';
import img12 from 'resources/projects/voces-x/voces-x-12.jpg';
// images.js
const images = [
  {
    id: 0,
    src: img00,
    title: 'Voces X - Imagen 0',
  },
  {
    id: 1,
    src: img01,
    title: 'Voces X - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Voces X - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Voces X - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Voces X - Imagen 4',
  },
  {
    id: 5,
    src: img05,
    title: 'Voces X - Imagen 5',
  },
  {
    id: 6,
    src: img06,
    title: 'Voces X - Imagen 6',
  },
  {
    id: 7,
    src: img07,
    title: 'Voces X - Imagen 7',
  },
  {
    id: 8,
    src: img08,
    title: 'Voces X - Imagen 8',
  },
  {
    id: 9,
    src: img09,
    title: 'Voces X - Imagen 9',
  },
  {
    id: 10,
    src: img10,
    title: 'Voces X - Imagen 10',
  },
  {
    id: 11,
    src: img11,
    title: 'Voces X - Imagen 11',
  },
  {
    id: 12,
    src: img12,
    title: 'Voces X - Imagen 12',
  },
];

export default images;
