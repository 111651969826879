import img01 from 'resources/projects/erres/erres-1.jpg';
import img02 from 'resources/projects/erres/erres-2.jpg';
import img03 from 'resources/projects/erres/erres-3.jpg';
import img04 from 'resources/projects/erres/erres-4.png';
import img05 from 'resources/projects/erres/erres-5.jpg';
import img06 from 'resources/projects/erres/erres-6.jpg';
import img07 from 'resources/projects/erres/erres-7.jpg';
import img08 from 'resources/projects/erres/erres-8.jpg';
import img09 from 'resources/projects/erres/erres-9.jpg';
import img10 from 'resources/projects/erres/erres-10.jpg';
import img11 from 'resources/projects/erres/erres-11.jpg';
// images.js
const images = [
  {
    id: 1,
    src: img01,
    title: 'Erres - Imagen 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Erres - Imagen 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Erres - Imagen 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Erres - Imagen 4',
  },
  {
    id: 5,
    src: img05,
    title: 'Erres - Imagen 5',
  },
  {
    id: 6,
    src: img06,
    title: 'Erres - Imagen 6',
  },
  {
    id: 7,
    src: img07,
    title: 'Erres - Imagen 7',
  },
  {
    id: 8,
    src: img08,
    title: 'Erres - Imagen 8',
  },
  {
    id: 9,
    src: img09,
    title: 'Erres - Imagen 9',
  },
  {
    id: 10,
    src: img10,
    title: 'Erres - Imagen 10',
  },
  {
    id: 11,
    src: img11,
    title: 'Erres - Imagen 11',
  },
];

export default images;
