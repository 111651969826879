import styled from 'styled-components';
import Heading from 'components/heading';
import { fontWideMedium } from 'styled/global';
import MediaBreakpoints from '../../../../styled/mediaBreakpoints';

type StyleProps = {
  cursorEmoji: string;
};

export const StyledHeading = styled(Heading)`
  font-size: 20px;
  ${fontWideMedium};
  @media ${MediaBreakpoints.mediumUp} {
    font-size: 26px;
  }
`;
export const StyledWrapper = styled.a<StyleProps>`
  color: inherit;
  cursor: ${(props) => {
    if (props.cursorEmoji) return `url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="32" width="32"><text y="28" font-size="32">${props.cursorEmoji}</text></svg>'), auto`;
    return 'auto';
  }};
  text-decoration: inherit;
`;
export const StyledEmoji = styled.span`
  @media ${MediaBreakpoints.largeUp} {
    display: none;
  }
`;
