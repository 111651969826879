import { deviceSizes } from './constants';

export const breakpoint = {
  smallHeightDown: `(max-height: ${deviceSizes.medium}px)`,
  smallUp: `(min-width: ${deviceSizes.small}px)`,
  mediumUp: `(min-width: ${deviceSizes.medium}px)`,
  largeUp: `(min-width: ${deviceSizes.large}px)`,
  extraLargeUp: `(min-width: ${deviceSizes.extraLarge}px)`,
};

export default breakpoint;
