import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { whiteTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Heading from 'components/heading';
import Image from 'components/image';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function VocesX(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Voces X la FP Dual 📓', 'Branding');
  });
  return (
    <ProjectDetail heading='Voces X la FP Dual 📓' categories={['Branding']} subheading='Fundación Bertelsmann, realizado en KREAB &nbsp;|&nbsp; 2023' theme={whiteTheme} setTheme={props.setTheme}>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Punto de partida"
        level={2}
        marginBottom={0.5}
      />
      <div>
        <p>
          Este proyecto tiene como objetivo fortalecer la implicación de los alumnos para que sus voces
          influyan directamente en la configuración futura de la FP Dual.
        </p>
      </div>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Requerimientos del proyecto"
        level={2}
        marginBottom={0.5}
      />
      <div>
        <p>
          Diseño de logotipo e identidad visual para impulsar la iniciativa Voces X la FP Dual.
        </p>
      </div>
      <div className="project-details__body__images">
        {images.map(({ id, src, title }) => (
          <React.Fragment key={id}>
            <Image imgSrc={src} imgAlt={title} animationDirection={AnimationDirection.Up}/>
          </React.Fragment>
        ))}
      </div>
    </ProjectDetail>
  );
}
