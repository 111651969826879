import img01 from 'resources/projects/real-state/realstate-1.jpg';
import img02 from 'resources/projects/real-state/realstate-2.jpg';
import img03 from 'resources/projects/real-state/realstate-3.jpg';
import img04 from 'resources/projects/real-state/realstate-4.jpg';

// images.js
const images = [
  {
    id: 1,
    src: img01,
    title: 'Image 1',
  },
  {
    id: 2,
    src: img02,
    title: 'Image 2',
  },
  {
    id: 3,
    src: img03,
    title: 'Image 3',
  },
  {
    id: 4,
    src: img04,
    title: 'Image 4',
  },
];

export default images;
