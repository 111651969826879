export const deviceSizes = {
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200,
};

export const fixedSizes = {
  headerHeight: '50px',
  headerHeightLg: '100px',
  bodyVerticalPadding: '2rem',
  bodyHorizontalPadding: '1rem',
};

export const palette = {
  amarillo: '#ffff9f',
  blanco: '#f8f9f4',
  negro: '#1E1E1E',
  verde: '#81ffa2',
  violeta: '#99a1de',
};

export const fontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export enum FontSize {
  xs = 0.5,
  s = 0.75,
  m = 1,
  ml = 1.33,
  l = 3,
  xl = 5,
  xxl = 7,
}

export const desktopMaxWidth = '1100px';
