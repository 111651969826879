import styled from 'styled-components';

type StyleProps = {
  isVisible: boolean;
  direction: AnimationDirection;
};

export enum AnimationDirection {
  Left,
  Right,
  Up,
  Down,
}

const StyledDiv = styled.div<StyleProps>`
    height: fit-content;
    opacity: ${(props) => (props.isVisible ? '1' : '0')};;
    transform: ${(props) => {
    if (props.isVisible) {
      return 'none';
    }
    switch (props.direction) {
      case AnimationDirection.Down:
        return 'translateY(-20vh)';
      case AnimationDirection.Left:
        return 'translateX(20vh)';
      case AnimationDirection.Right:
        return 'translateX(-20vh)';
      case AnimationDirection.Up:
        return 'translateY(20vh)';
      default:
        return 'none';
    }
  }};
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
`;
export default StyledDiv;
