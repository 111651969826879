import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { darkTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Heading from 'components/heading';
import Image from 'components/image';
import Separator from 'components/separator';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function LaHendija(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Catálogo La Hendija 📓', 'Diseño editorial');
  });
  return (
    <ProjectDetail heading='Catálogo La Hendija 📓' categories={['Diseño editorial', 'Dirección de arte']} subheading='La Hendija &nbsp;|&nbsp; 2018' theme={darkTheme} setTheme={props.setTheme}>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Punto de partida"
        level={2}
        marginBottom={0.5}
      />
      <div>
        <p>
          La Editorial Fundación La Hendija se fundó en el año 2009 en un antiguo taller de rectificación
          de motores ubicado en la ciudad de Paraná.
          En 2018, surge la necesidad de elaborar un catálogo que recopila todas las obras publicadas
          hasta el momento en formato digital y físico.
        </p>
      </div>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Requerimientos del proyecto"
        level={2}
        marginBottom={0.5}
      />
      <div>
        <p>
          Este catálogo, se convirtió en una oportunidad para dar a conocer, no solo las obras publicadas
          sino también la editorial y su historia.
          Con esa idea en mente, se decidió hacer un shooting de la editorial e incluir este material en el catálogo.
        </p>
      </div>
      <div className="project-details__body__images">
        {images.map(({ id, src, title }) => (
          <React.Fragment key={id}>
            <Image imgSrc={src} imgAlt={title} animationDirection={AnimationDirection.Up}/>
            <Separator marginTop={1} marginBottom={1}></Separator>
          </React.Fragment>
        ))}
      </div>
    </ProjectDetail>
  );
}
