import { Component } from 'react';
import { DefaultTheme } from 'styled-components';
import StyledTextLine from './styles';
import { FontSize } from '../../styled/constants';

type Props = {
  className?: string;
  color?: string;
  alignment?: 'left' | 'center' | 'right';
  font: string;
  text: string;
  size?: FontSize;
  theme?: DefaultTheme;
  noBreak?: boolean;
};

class TextLine extends Component<Props> {
  render(): JSX.Element {
    const {
      className, color, font, size, text, theme, noBreak, alignment,
    } = this.props;
    return (
      <>
        <StyledTextLine
          aligment={alignment}
          color={color || theme?.primary.textColor}
          font={font}
          size={size}
          className={className}
        >
          {text}
        </StyledTextLine>
        {!noBreak && <br></br>}
      </>
    );
  }
}

export default TextLine;
