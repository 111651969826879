import styled from 'styled-components';
import { desktopMaxWidth, fixedSizes } from 'styled/constants';
import Separator from 'components/separator';
import MediaBreakpoints from '../../styled/mediaBreakpoints';

const verticalMargin = '40px';

export const StyledApp = styled.main`
  display: flex;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  max-width: ${desktopMaxWidth};
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  @media ${MediaBreakpoints.largeUp} {
    margin: 0 120px ${verticalMargin};
    max-width: 100%;
    width: auto;
  }
`;

export const StyledSeparator = styled(Separator)`
  display: none;
  @media ${MediaBreakpoints.largeUp} {
    display: block;
    left: 0;
    position: absolute;
    top: ${fixedSizes.headerHeightLg};
  }
`;
