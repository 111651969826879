import styled, { css } from 'styled-components';
import { FontSize } from 'styled/constants';

type StyleProps = {
  alignment: 'left' | 'center' | 'right';
  marginTop?: number;
  marginBottom?: number;
};

const baseStyles = css<StyleProps>`
  margin: 0 0;
  ${(props) => {
    if (props.marginTop || props.marginBottom) {
      return `
        margin-top: ${props.marginTop || 0}rem;
        margin-bottom: ${props.marginBottom || 0}rem;
      `;
    }
    return '';
  }};
  text-align: ${(props) => props.alignment};
  white-space: pre-line;
  width: 100%;
`;

export const StyledHeading1 = styled.h1`
  ${baseStyles};
  font-size: ${FontSize.xl.valueOf()}rem;
`;

export const StyledHeading2 = styled.h2`
  ${baseStyles};
  font-size: ${FontSize.l.valueOf()}rem;
`;

export const StyledHeading3 = styled.h3`
  ${baseStyles};
  font-size: ${FontSize.ml.valueOf()}rem;
`;
