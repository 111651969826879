import PageWrapper from 'components/pageWrapper';
import { DefaultTheme } from 'styled-components';
import TextLine from 'components/textLine';
import React, { useEffect } from 'react';
import Heading from 'components/heading';
import Container from 'components/container';
import { fontNormal } from 'styled/global';
import ProjectDetailsStyle, { StyledCategoriesContainer, StyledCategory } from './styles';

// We define the props that will receive the setTheme
type Props = {
  categories: string[];
  children: React.ReactNode;
  heading: string;
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
  subheading: string;
  theme: DefaultTheme;
};

export default function ProjectDetail(props: Props): JSX.Element {
  useEffect(() => {
    props.setTheme(props.theme);
  });
  return (
    <PageWrapper cssStyling={ProjectDetailsStyle} heightAuto={true}>
      <Container flexDirection="column" spaceGap={4}>
        {/* Cabecera */}
        <Container
          flexDirection="column"
          spaceGap={0}
          align="flex-start"
          justifyContent="flex-start"
        >
          <Heading
            alignment="left"
            className="project-details__heading"
            label={props.heading}
            marginBottom={0.75}
          />
          <TextLine
            className="project-details__subheading"
            theme={props.theme}
            text={props.subheading}
            font={fontNormal}
            noBreak={true}
          />
          <StyledCategoriesContainer
            flexDirection="row"
            spaceGap={0}
            align="flex-start"
            justifyContent="flex-start"
          >
            {props.categories.map((currentValue, index, arr) => (
              <React.Fragment key={index}>
                <StyledCategory
                  theme={props.theme}
                  text={currentValue}
                  font={fontNormal}
                  noBreak={true}
                />
                {index < (arr.length - 1) && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
              </React.Fragment>
            ))}

          </StyledCategoriesContainer>
          {/* Contenido */}
          <Container
            align="flex-start"
            className='project-details__body'
            flexDirection="column"
            spaceGap={0}
            justifyContent="flex-start"
          >
            {props.children}
          </Container>
        </Container>
      </Container>
    </PageWrapper>
  );
}
